var sagePayValidate = {
    errors: [],

    html: '',

    /**
     * Does the form pass all the validation
     * return Boolean
     */
    validateAll: function () {
        this.errors = [];
        this.validateCardHolderName();
        this.validateCardNumber();
        this.validateExpiryMonth();
        this.validateExpiryYear();
        this.validateSecurityCode();
        this.validateBillingAddress();
        this.validateBillingPostcode();
        this.validateBillingTown();

        if (this.errors.length > 0) {
            this.displayErrorHTML();
        } else {
            this.hideErrorMessage();
        }

        console.log(this.errors);

        return this.errors.length == 0;
    },

    validateCardHolderName: function () {
        var cardholderName = $('.js-cardholder-name').val();
        if (cardholderName == '') this.errors.push('Cardholder name must not be empty');
    },

    validateCardNumber: function () {
        var cardNumber = $('.js-card-number').val();
        if (cardNumber.length != 16) this.errors.push('Card number must be 16 digits');
    },

    validateExpiryMonth: function () {
        var expiryMonth = $('.js-expiry-month option:selected').val();
        if (expiryMonth == 0) this.errors.push('You must select an expiry month');
    },

    validateExpiryYear: function () {
        var expiryYear = $('.js-expiry-year option:selected').val();
        if (expiryYear == 0) this.errors.push('You must select an expiry year');
    },

    validateSecurityCode: function () {
        var cardNumber = $('.js-security-code').val();
        if (cardNumber.length != 3) this.errors.push('Security must be 3 digits');
    },

    validateBillingAddress: function () {
        var billingAddress = $('.js-billing-address-1').val();
        if (billingAddress.length == 0) this.errors.push('You must enter billing address line 1');
    },

    validateBillingTown: function () {
        var billingTown = $('.js-billing-town').val();
        if (billingTown.length == 0) this.errors.push('You must select billing town');
    },

    validateBillingPostcode: function () {
        var billingPostcode = $('.js-billing-postcode').val();
        if (billingPostcode.length < 3) this.errors.push('Billing postcode is too short');
        if (billingPostcode.length > 8) this.errors.push('Billing postcode is too long');
    },

    /**
     * Returns the html for the error messages
     */
    makeErrorHTML: function () {
        var html = '';

        this.errors.forEach(function (error) {
            html += '<p><span class="icon-warning"></span> ' + error + '</p>';
        })
        return html;
    },

    displayErrorHTML: function () {
        $('.js-error-message').html(this.makeErrorHTML()).show();
    },

    hideErrorMessage: function () {
        $('.js-error-message').hide();
    },

    convertExpiryDate: function () {
        var month = $('.js-expiry-month option:selected').val();
        var year = $('.js-expiry-year option:selected').val();

        $('.js-expiry-date').val(month + year);
    }
}
