/**
 * Adds the totals to the footer of a bootstrap table
 * @param data
 * @returns {string}
 */
function footer_sum(data){
    var field = this.field;

    var total_sum = data.reduce(function(sum, row) {
        console.log(sum);
        return (sum) + (parseInt(row[field]) || 0);
    }, 0);

    return '&pound;' + total_sum.toLocaleString();
}

/**
 * Gets the lable for the footer
 * @param data
 * @returns {string}
 */
function footer_label(data){
    return 'Totals';
}
