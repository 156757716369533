var refresher = {
    refresh_interval: 10,

    suspend_refresh: false,

    timer_element: '#refresh_timer',

    /**
     * Initailaization
     */
    init: function () {
        refresher.set_suspend_refresh();

        $('.js-pause-refresh').click(function () {
            refresher.set_suspend_refresh();
        });
        $('.js-play-refresh').click(function () {
            refresher.set_resume_refresh();
        });
    },

    /**
     *  Starts the timer
     */
    start_timer: function (duration) {
        var timer = duration, minutes, seconds;
        var refresh_interval = setInterval(function () {
            minutes = parseInt(timer / 60, 10);
            seconds = parseInt(timer % 60, 10);

            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;

            if(!refresher.suspend_refresh){
                $(refresher.timer_element).html(minutes + ":" + seconds);
            }

            if (--timer < 0) {
                if (!refresher.suspend_refresh) {
                    clearInterval(refresh_interval);
                    $(refresher.timer_element).html('Reloading ...');
                    window.location.reload();
                }
            }
        }, 1000);
    },

    /**
     * This gets the number of seconds from the last five minute
     * @returns {number}
     */
    get_refresh_seconds: function () {
        var now = moment(new Date());
        var remainder = 5 - now.minute() % 5;
        var refresh_time = moment().add(remainder, 'minutes');
        refresh_time.second(0);
        var duration = moment.duration(refresh_time.diff(now));

        duration = duration.asSeconds();

        return (duration < 10) ? 300 : 300 - (300 - duration); //this prevents constant reloads if only few seconds left to next 5 minutes
    },

    /**
     * Suspends the page
     */
    set_suspend_refresh: function () {
        $(refresher.timer_element).html('PAUSED');
        $('.js-play-refresh').show();
        $('.js-pause-refresh').hide();
        this.suspend_refresh = true;
    },

    /**
     *  Starts the page again
     */
    set_resume_refresh: function () {
        $('.js-play-refresh').hide();
        $('.js-pause-refresh').show();
        this.suspend_refresh = false;
    }
}
