var BankChecker = {
    validate: function (AccountNumber, SortCode, CallBack) {
        $.getJSON("https://services.postcodeanywhere.co.uk/BankAccountValidation/Interactive/Validate/v2.00/json3.ws?callback=?",
            {
                Key: 'FG99-JR44-ET75-TJ91',
                AccountNumber: AccountNumber,
                SortCode: SortCode
            },
            function (data) {
                console.log(data);
                if (data.Items.length > 0 && typeof(data.Items[0]) != "undefined") {
                    if (data.Items[0].IsCorrect) {
                        //run a local function sent by request
                        window[CallBack](data.Items[0]);
                    }else{
                        window[CallBack](data);
                    }
                }
                else {
                    window[CallBack](data);
                }
            });
    }
};


